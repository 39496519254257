// Colours
$transparent-basic-32: rgba(148, 148, 148, 0.32);
$transparent-error-24: rgba(218, 30, 40, 0.24);
$success-30: #70de8d;
$transparent-basic-black-80: rgba(54, 54, 54, 0.8);
$error-70: #a2191f;
$transparent-basic-90: rgba(148, 148, 148, 0.9);
$transparent-success-24: rgba(36, 163, 73, 0.24);
$transparent-primary-90: rgba(250, 130, 57, 0.9);
$primary-70: #e8580a;
$transparent-success-8: rgba(36, 163, 73, 0.08);
$basic-10: #ffffff;
$primary-80: #d04a03;
$basic-80: #525252;
$transparent-error-40: rgba(218, 30, 40, 0.4);
$basic-60: #949494;
$primary-100: #8b3306;
$basic-20: #f5f5f5;
$error-90: #520408;
$error-40: #ff8489;
$error-30: #ffb3b8;
$primary-20: #ffd6bd;
$transparent-success-90: rgba(36, 163, 73, 0.9);
$error-100: #2d0609;
$basic-110: #0d0d0d;
$basic-30: #e8e8e8;
$primary-60: #f66c18;
$success-20: #b0f5c2;
$transparent-error-90: rgba(218, 30, 40, 0.9);
$success-100: #0a240b;
$transparent-basic-40: rgba(148, 148, 148, 0.4);
$transparent-basic-50: rgba(148, 148, 148, 0.5);
$error-80: #750f13;
$transparent-error-50: rgba(218, 30, 40, 0.5);
$error-20: #ffd6d9;
$primary-90: #af3d01;
$transparent-basic-24: rgba(148, 148, 148, 0.24);
$success-40: #43c267;
$transparent-primary-32: rgba(250, 130, 57, 0.32);
$error-10: #fff1f1;
$basic-70: #737373;
$transparent-error-32: rgba(218, 30, 40, 0.32);
$success-60: #198239;
$transparent-success-16: rgba(36, 163, 73, 0.16);
$transparent-error-16: rgba(218, 30, 40, 0.16);
$basic-90: #363636;
$primary-10: #ffeee4;
$primary-40: #fd9b60;
$success-10: #dffce7;
$transparent-basic-16: rgba(148, 148, 148, 0.16);
$primary-50: #fa8239;
$basic-50: #b3b3b3;
$transparent-success-40: rgba(36, 163, 73, 0.4);
$basic-40: #cecece;
$transparent-error-8: rgba(218, 30, 40, 0.08);
$error-50: #f94d56;
$success-80: #044719;
$transparent-primary-40: rgba(250, 130, 57, 0.4);
$transparent-primary-16: rgba(250, 130, 57, 0.16);
$error-60: #da1e28;
$transparent-basic-white-90: rgba(255, 255, 255, 0.9);
$transparent-success-32: rgba(36, 163, 73, 0.32);
$basic-100: #161616;
$success-70: #0e5e26;
$success-50: #24a349;
$transparent-basic-8: rgba(148, 148, 148, 0.08);
$transparent-primary-8: rgba(250, 130, 57, 0.08);
$transparent-success-50: rgba(36, 163, 73, 0.5);
$transparent-primary-50: rgba(250, 130, 57, 0.5);
$transparent-primary-24: rgba(250, 130, 57, 0.24);
$success-90: #02330f;
$primary-30: #feb78c;

.transparent-basic-32 {
  color: $transparent-basic-32;
}
.transparent-error-24 {
  color: $transparent-error-24;
}
.success-30 {
  color: $success-30;
}
.transparent-basic-black-80 {
  color: $transparent-basic-black-80;
}
.error-70 {
  color: $error-70;
}
.transparent-basic-90 {
  color: $transparent-basic-90;
}
.transparent-success-24 {
  color: $transparent-success-24;
}
.transparent-primary-90 {
  color: $transparent-primary-90;
}
.primary-70 {
  color: $primary-70;
}
.transparent-success-8 {
  color: $transparent-success-8;
}
.basic-10 {
  color: $basic-10;
}
.primary-80 {
  color: $primary-80;
}
.basic-80 {
  color: $basic-80;
}
.transparent-error-40 {
  color: $transparent-error-40;
}
.basic-60 {
  color: $basic-60;
}
.primary-100 {
  color: $primary-100;
}
.basic-20 {
  color: $basic-20;
}
.error-90 {
  color: $error-90;
}
.error-40 {
  color: $error-40;
}
.error-30 {
  color: $error-30;
}
.primary-20 {
  color: $primary-20;
}
.transparent-success-90 {
  color: $transparent-success-90;
}
.error-100 {
  color: $error-100;
}
.basic-110 {
  color: $basic-110;
}
.basic-30 {
  color: $basic-30;
}
.primary-60 {
  color: $primary-60;
}
.success-20 {
  color: $success-20;
}
.transparent-error-90 {
  color: $transparent-error-90;
}
.success-100 {
  color: $success-100;
}
.transparent-basic-40 {
  color: $transparent-basic-40;
}
.transparent-basic-50 {
  color: $transparent-basic-50;
}
.error-80 {
  color: $error-80;
}
.transparent-error-50 {
  color: $transparent-error-50;
}
.error-20 {
  color: $error-20;
}
.primary-90 {
  color: $primary-90;
}
.transparent-basic-24 {
  color: $transparent-basic-24;
}
.success-40 {
  color: $success-40;
}
.transparent-primary-32 {
  color: $transparent-primary-32;
}
.error-10 {
  color: $error-10;
}
.basic-70 {
  color: $basic-70;
}
.transparent-error-32 {
  color: $transparent-error-32;
}
.success-60 {
  color: $success-60;
}
.transparent-success-16 {
  color: $transparent-success-16;
}
.transparent-error-16 {
  color: $transparent-error-16;
}
.basic-90 {
  color: $basic-90;
}
.primary-10 {
  color: $primary-10;
}
.primary-40 {
  color: $primary-40;
}
.success-10 {
  color: $success-10;
}
.transparent-basic-16 {
  color: $transparent-basic-16;
}
.primary-50 {
  color: $primary-50;
}
.basic-50 {
  color: $basic-50;
}
.transparent-success-40 {
  color: $transparent-success-40;
}
.basic-40 {
  color: $basic-40;
}
.transparent-error-8 {
  color: $transparent-error-8;
}
.error-50 {
  color: $error-50;
}
.success-80 {
  color: $success-80;
}
.transparent-primary-40 {
  color: $transparent-primary-40;
}
.transparent-primary-16 {
  color: $transparent-primary-16;
}
.error-60 {
  color: $error-60;
}
.transparent-basic-white-90 {
  color: $transparent-basic-white-90;
}
.transparent-success-32 {
  color: $transparent-success-32;
}
.basic-100 {
  color: $basic-100;
}
.success-70 {
  color: $success-70;
}
.success-50 {
  color: $success-50;
}
.transparent-basic-8 {
  color: $transparent-basic-8;
}
.transparent-primary-8 {
  color: $transparent-primary-8;
}
.transparent-success-50 {
  color: $transparent-success-50;
}
.transparent-primary-50 {
  color: $transparent-primary-50;
}
.transparent-primary-24 {
  color: $transparent-primary-24;
}
.success-90 {
  color: $success-90;
}
.primary-30 {
  color: $primary-30;
}
