@import './colours';

// Typography
@font-face {
  font-family: PingFangSC;
  src: local(PingFangSC-Regular);
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: PingFangSC;
  src: local(PingFangSC-Semibold);
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

$pingfang: PingFangSC, 'Helvetica Neue', Helvetica, Arial, sans-serif;

@mixin H1-Headline---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  text-align: right;
  color: $basic-90;
}

@mixin H1-Headline---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  text-align: right;
  color: $basic-10;
}

@mixin H1-Headline---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  text-align: right;
  color: $primary-60;
}

@mixin H1-Headline---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  color: $basic-90;
}

@mixin H1-Headline---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  color: $basic-10;
}

@mixin H1-Headline---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  color: $primary-60;
}

@mixin H1-Headline---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  text-align: center;
  color: $basic-90;
}

@mixin H1-Headline---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  text-align: center;
  color: $basic-10;
}

@mixin H1-Headline---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 32px;
  font-weight: 900;
  line-height: 1.25;
  text-align: center;
  color: $primary-60;
}

@mixin H2-Headline---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-90;
}

@mixin H2-Headline---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-10;
}

@mixin H2-Headline---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $primary-60;
}

@mixin H2-Headline---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-90;
}

@mixin H2-Headline---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-10;
}

@mixin H2-Headline---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  color: $primary-60;
}

@mixin H2-Headline---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-90;
}

@mixin H2-Headline---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-10;
}

@mixin H2-Headline---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 26px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $primary-60;
}

@mixin H3-Headline---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $success-50;
}

@mixin H3-Headline---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $basic-70;
}

@mixin H3-Headline---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $error-60;
}

@mixin H3-Headline---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin H3-Headline---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $basic-90;
}

@mixin H3-Headline---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $basic-10;
}

@mixin H3-Headline---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $primary-60;
}

@mixin H3-Headline---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $success-50;
}

@mixin H3-Headline---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $basic-70;
}

@mixin H3-Headline---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $error-60;
}

@mixin H3-Headline---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $transparent-basic-50;
}

@mixin H3-Headline---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $basic-90;
}

@mixin H3-Headline---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $basic-10;
}

@mixin H3-Headline---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  color: $primary-60;
}

@mixin H3-Headline---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $success-50;
}

@mixin H3-Headline---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $basic-70;
}

@mixin H3-Headline---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin H3-Headline---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $basic-90;
}

@mixin H3-Headline---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $basic-10;
}

@mixin H3-Headline---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $primary-60;
}

@mixin H3-Headline---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 20px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $error-60;
}

@mixin Button-Aa--18-Giant---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-Aa---18-Giant---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $success-50;
}

@mixin Button-Aa---18-Giant---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-Aa---18-Giant---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $error-60;
}

@mixin Button-Aa---18-Giant---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-Aa---18-Giant---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-Aa---18-Giant---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-Aa---18-Giant---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-Aa---18-Giant---Left-Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $basic-10;
}

@mixin Button-Aa---18-Giant---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $success-50;
}

@mixin Button-Aa---18-Giant---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $basic-70;
}

@mixin Button-Aa---18-Giant---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $error-60;
}

@mixin Button-Aa---18-Giant---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $transparent-basic-50;
}

@mixin Button-Aa---18-Giant---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $basic-90;
}

@mixin Button-Aa---18-Giant---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  color: $primary-60;
}

@mixin Button-Aa---18-Giant---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $success-50;
}

@mixin Button-Aa---18-Giant---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $error-60;
}

@mixin Button-Aa---18-Giant---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-Aa---18-Giant---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-Aa---18-Giant---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-Aa---18-Giant---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.1px;
  text-align: center;
  color: $primary-60;
}

@mixin Button-AA---18-Giant---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $success-50;
}

@mixin Button-AA---18-Giant---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-AA---18-Giant---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $error-60;
}

@mixin Button-AA---18-Giant---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-AA---18-Giant---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-AA---18-Giant---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-AA---18-Giant---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-AA---18-Giant---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $success-50;
}

@mixin Button-AA---18-Giant---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $basic-70;
}

@mixin Button-AA---18-Giant---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $error-60;
}

@mixin Button-AA---18-Giant---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $transparent-basic-50;
}

@mixin Button-AA---18-Giant---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $basic-90;
}

@mixin Button-AA---18-Giant---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $basic-10;
}

@mixin Button-AA---18-Giant---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  color: $primary-60;
}

@mixin Button-AA---18-Giant---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $success-50;
}

@mixin Button-AA---18-Giant---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-AA---18-Giant---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $error-60;
}

@mixin Button-AA---18-Giant---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-AA---18-Giant---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-AA---18-Giant---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-AA---18-Giant---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 18px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.3px;
  text-align: center;
  color: $primary-60;
}

@mixin H4-Headline---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $success-50;
}

@mixin H4-Headline---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $basic-70;
}

@mixin H4-Headline---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $error-60;
}

@mixin H4-Headline---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin H4-Headline---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $basic-90;
}

@mixin H4-Headline---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $basic-10;
}

@mixin H4-Headline---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: right;
  color: $primary-60;
}

@mixin H4-Headline---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $success-50;
}

@mixin H4-Headline---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $basic-70;
}

@mixin H4-Headline---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $error-60;
}

@mixin H4-Headline---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $transparent-basic-50;
}

@mixin H4-Headline---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $basic-90;
}

@mixin H4-Headline---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $basic-10;
}

@mixin H4-Headline---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  color: $primary-60;
}

@mixin H4-Headline---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $success-50;
}

@mixin H4-Headline---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $basic-70;
}

@mixin H4-Headline---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $error-60;
}

@mixin H4-Headline---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin H4-Headline---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $basic-90;
}

@mixin H4-Headline---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $basic-10;
}

@mixin H4-Headline---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  text-align: center;
  color: $primary-60;
}

@mixin Button-Aa---16-Large---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $success-50;
}

@mixin Button-Aa---16-Large---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-Aa---16-Large---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $error-60;
}

@mixin Button-Aa---16-Large---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-Aa---16-Large---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-Aa---16-Large---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-Aa---16-Large---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-Aa---16-Large---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $success-50;
}

@mixin Button-Aa---16-Large---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $basic-70;
}

@mixin Button-Aa---16-Large---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $error-60;
}

@mixin Button-Aa---16-Large---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $transparent-basic-50;
}

@mixin Button-Aa---16-Large---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $basic-90;
}

@mixin Button-Aa---16-Large---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $basic-10;
}

@mixin Button-Aa---16-Large---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  color: $primary-60;
}

@mixin Button-Aa---16-Large---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $success-50;
}

@mixin Button-Aa---16-Large---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-Aa---16-Large---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $error-60;
}

@mixin Button-Aa---16-Large---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-Aa---16-Large---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-Aa---16-Large---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-Aa---16-Large---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.1px;
  text-align: center;
  color: $primary-60;
}

@mixin Button-AA---16-Large---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $success-50;
}

@mixin Button-AA---16-Large---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-AA---16-Large---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $error-60;
}

@mixin Button-AA---16-Large---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-AA---16-Large---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-AA---16-Large---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-AA---16-Large---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-AA---16-Large---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $success-50;
}

@mixin Button-AA---16-Large---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $basic-70;
}

@mixin Button-AA---16-Large---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $error-60;
}

@mixin Button-AA---16-Large---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $transparent-basic-50;
}

@mixin Button-AA---16-Large---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $basic-90;
}

@mixin Button-AA---16-Large---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $basic-10;
}

@mixin Button-AA---16-Large---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  color: $primary-60;
}

@mixin Button-AA---16-Large---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $success-50;
}

@mixin Button-AA---16-Large---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-AA---16-Large---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $error-60;
}

@mixin Button-AA---16-Large---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-AA---16-Large---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-AA---16-Large---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-AA---16-Large---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 16px;
  font-weight: 900;
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: center;
  color: $primary-60;
}

@mixin Button-Aa---14-Medium---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $success-50;
}

@mixin Button-Aa---14-Medium---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-Aa---14-Medium---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $error-60;
}

@mixin Button-Aa---14-Medium---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-Aa---14-Medium---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-Aa---14-Medium---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-Aa---14-Medium---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-Aa---14-Medium---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $success-50;
}

@mixin Button-Aa---14-Medium---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $basic-70;
}

@mixin Button-Aa---14-Medium---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $error-60;
}

@mixin Button-Aa---14-Medium---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $transparent-basic-50;
}

@mixin Button-Aa---14-Medium---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $basic-90;
}

@mixin Button-Aa---14-Medium---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $basic-10;
}

@mixin Button-Aa---14-Medium---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  color: $primary-60;
}

@mixin Button-Aa---14-Medium---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $success-50;
}

@mixin Button-Aa---14-Medium---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-Aa---14-Medium---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $error-60;
}

@mixin Button-Aa---14-Medium---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-Aa---14-Medium---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-Aa---14-Medium---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-Aa---14-Medium---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.1px;
  text-align: center;
  color: $primary-60;
}

@mixin Button-AA---14-Medium---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $success-50;
}

@mixin Button-AA---14-Medium---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-AA---14-Medium---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $error-60;
}

@mixin Button-AA---14-Medium---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-AA---14-Medium---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-AA---14-Medium---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-AA---14-Medium---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-AA---14-Medium---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $success-50;
}

@mixin Button-AA---14-Medium---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $basic-70;
}

@mixin Button-AA---14-Medium---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $error-60;
}

@mixin Button-AA---14-Medium---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $transparent-basic-50;
}

@mixin Button-AA---14-Medium---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $basic-90;
}

@mixin Button-AA---14-Medium---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $basic-10;
}

@mixin Button-AA---14-Medium---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  color: $primary-60;
}

@mixin Button-AA---14-Medium---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $success-50;
}

@mixin Button-AA---14-Medium---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-AA---14-Medium---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $error-60;
}

@mixin Button-AA---14-Medium---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-AA---14-Medium---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-AA---14-Medium---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-AA---14-Medium---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 14px;
  font-weight: 900;
  line-height: 1.14;
  letter-spacing: 0.4px;
  text-align: center;
  color: $primary-60;
}

@mixin L1-Link---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $success-50;
}

@mixin L1-Link---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-70;
}

@mixin L1-Link---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $error-60;
}

@mixin L1-Link---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin L1-Link---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-90;
}

@mixin L1-Link---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-10;
}

@mixin L1-Link---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $primary-60;
}

@mixin L1-Link---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $success-50;
}

@mixin L1-Link---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-70;
}

@mixin L1-Link---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $error-60;
}

@mixin L1-Link---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $transparent-basic-50;
}

@mixin L1-Link---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-90;
}

@mixin L1-Link---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-10;
}

@mixin L1-Link---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $primary-60;
}

@mixin L1-Link---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $success-50;
}

@mixin L1-Link---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-70;
}

@mixin L1-Link---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $error-60;
}

@mixin L1-Link---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin L1-Link---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-90;
}

@mixin L1-Link---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-10;
}

@mixin L1-Link---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $primary-60;
}

@mixin H5-Headline---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $success-50;
}

@mixin H5-Headline---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $basic-70;
}

@mixin H5-Headline---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $error-60;
}

@mixin H5-Headline---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin H5-Headline---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $basic-90;
}

@mixin H5-Headline---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $basic-10;
}

@mixin H5-Headline---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: right;
  color: $primary-60;
}

@mixin H5-Headline---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $success-50;
}

@mixin H5-Headline---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $basic-70;
}

@mixin H5-Headline---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $error-60;
}

@mixin H5-Headline---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $transparent-basic-50;
}

@mixin H5-Headline---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $basic-90;
}

@mixin H5-Headline---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $basic-10;
}

@mixin H5-Headline---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  color: $primary-60;
}

@mixin H5-Headline---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $success-50;
}

@mixin H5-Headline---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $basic-70;
}

@mixin H5-Headline---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $error-60;
}

@mixin H5-Headline---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin H5-Headline---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $basic-90;
}

@mixin H5-Headline---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $basic-10;
}

@mixin H5-Headline---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  letter-spacing: 1.8px;
  text-align: center;
  color: $primary-60;
}

@mixin B4-Body---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $success-50;
}

@mixin B4-Body---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $basic-70;
}

@mixin B4-Body---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $error-60;
}

@mixin B4-Body---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin B4-Body---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $basic-90;
}

@mixin B4-Body---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $basic-10;
}

@mixin B4-Body---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: right;
  color: $primary-60;
}

@mixin B4-Body---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $success-50;
}

@mixin B4-Body---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $basic-70;
}

@mixin B4-Body---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $error-60;
}

@mixin B4-Body---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $transparent-basic-50;
}

@mixin B4-Body---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $basic-90;
}

@mixin B4-Body---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $basic-10;
}

@mixin B4-Body---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  color: $primary-60;
}

@mixin B4-Body---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $success-50;
}

@mixin B4-Body---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $basic-70;
}

@mixin B4-Body---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $error-60;
}

@mixin B4-Body---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin B4-Body---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $basic-90;
}

@mixin B4-Body---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $basic-10;
}

@mixin B4-Body---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.85;
  text-align: center;
  color: $primary-60;
}

@mixin B3-Body---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $success-50;
}

@mixin B3-Body---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-70;
}

@mixin B3-Body---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $error-60;
}

@mixin B3-Body---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin B3-Body---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-90;
}

@mixin B3-Body---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $basic-10;
}

@mixin B3-Body---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: right;
  color: $primary-60;
}

@mixin B3-Body---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-90;
}

@mixin B3-Body---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $success-50;
}

@mixin B3-Body---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-70;
}

@mixin B3-Body---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $error-60;
}

@mixin B3-Body---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $transparent-basic-50;
}

@mixin B3-Body---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $basic-10;
}

@mixin B3-Body---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  color: $primary-60;
}

@mixin B3-Body---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $success-50;
}

@mixin B3-Body---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-70;
}

@mixin B3-Body---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $error-60;
}

@mixin B3-Body---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin B3-Body---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-90;
}

@mixin B3-Body---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $basic-10;
}

@mixin B3-Body---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 13px;
  font-weight: 900;
  line-height: 1.23;
  text-align: center;
  color: $primary-60;
}

@mixin B2-Body---Right---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $success-50;
}

@mixin B2-Body---Right---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $basic-70;
}

@mixin B2-Body---Right---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $error-60;
}

@mixin B2-Body---Right---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin B2-Body---Right---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $basic-90;
}

@mixin B2-Body---Right---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $basic-10;
}

@mixin B2-Body---Right---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: right;
  color: $primary-60;
}

@mixin B2-Body---Left---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $success-50;
}

@mixin B2-Body---Left---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $basic-70;
}

@mixin B2-Body---Left---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $error-60;
}

@mixin B2-Body---Left---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $transparent-basic-50;
}

@mixin B2-Body---Left---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $basic-90;
}

@mixin B2-Body---Left---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $basic-10;
}

@mixin B2-Body---Left---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  color: $primary-60;
}

@mixin B2-Body---Center---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $success-50;
}

@mixin B2-Body---Center---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $basic-70;
}

@mixin B2-Body---Center---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $error-60;
}

@mixin B2-Body---Center---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin B2-Body---Center---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $basic-90;
}

@mixin B2-Body---Center---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $basic-10;
}

@mixin B2-Body---Center---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.85;
  text-align: center;
  color: $primary-60;
}

@mixin B1-Body---Right---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $success-50;
}

@mixin B1-Body---Right---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $basic-70;
}

@mixin B1-Body---Right---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $error-60;
}

@mixin B1-Body---Right---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin B1-Body---Right---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $basic-90;
}

@mixin B1-Body---Right---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $basic-10;
}

@mixin B1-Body---Right---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: right;
  color: $primary-60;
}

@mixin B1-Body---Left---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $success-50;
}

@mixin B1-Body---Left---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $basic-70;
}

@mixin B1-Body---Left---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $error-60;
}

@mixin B1-Body---Left---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $transparent-basic-50;
}

@mixin B1-Body---Left---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $basic-90;
}

@mixin B1-Body---Left---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $basic-10;
}

@mixin B1-Body---Left---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  color: $primary-60;
}

@mixin B1-Body---Center---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $success-50;
}

@mixin B1-Body---Center---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $basic-70;
}

@mixin B1-Body---Center---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $error-60;
}

@mixin B1-Body---Center---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin B1-Body---Center---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $basic-90;
}

@mixin B1-Body---Center---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $basic-10;
}

@mixin B1-Body---Center---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 13px;
  line-height: 1.23;
  text-align: center;
  color: $primary-60;
}

@mixin Button-Aa---2-Small---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $error-60;
}

@mixin Button-Aa---12-Small---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $success-50;
}

@mixin Button-Aa---12-Small---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $basic-70;
}

@mixin Button-Aa---12-Small---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $error-60;
}

@mixin Button-Aa---12-Small---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-Aa---12-Small---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $basic-90;
}

@mixin Button-Aa---12-Small---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $basic-10;
}

@mixin Button-Aa---12-Small---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: right;
  color: $primary-60;
}

@mixin Button-Aa---12-Small---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $success-50;
}

@mixin Button-Aa---12-Small---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $basic-70;
}

@mixin Button-Aa---12-Small---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $error-60;
}

@mixin Button-Aa---12-Small---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $transparent-basic-50;
}

@mixin Button-Aa---12-Small---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $basic-90;
}

@mixin Button-Aa---12-Small---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $basic-10;
}

@mixin Button-Aa---12-Small---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  color: $primary-60;
}

@mixin Button-Aa---12-Small---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $basic-70;
}

@mixin Button-Aa---12-Small---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-Aa---12-Small---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $basic-90;
}

@mixin Button-Aa---12-Small---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $basic-10;
}

@mixin Button-Aa---12-Small---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $primary-60;
}

@mixin Button-Aa---12-Small---Cente---Success-Support-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  text-align: center;
  color: $success-50;
}

@mixin Button-AA---12-Small---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $success-50;
}

@mixin Button-AA---12-Small---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-AA---12-Small---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $error-60;
}

@mixin Button-AA---12-Small---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-AA---12-Small---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-AA---12-Small---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-AA---12-Small---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-AA---12-Small---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $success-50;
}

@mixin Button-AA---12-Small---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $error-60;
}

@mixin Button-AA---12-Small---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $transparent-basic-50;
}

@mixin Button-AA---12-Small---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $basic-90;
}

@mixin Button-AA---12-Small---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $basic-10;
}

@mixin Button-AA---12-Small---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $primary-60;
}

@mixin Button-AA---12-Small---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $success-50;
}

@mixin Button-AA---12-Small---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-AA---12-Small---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $error-60;
}

@mixin Button-AA---12-Small---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-AA---12-Small---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-AA---12-Small---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-AA---12-Small---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  text-align: center;
  color: $primary-60;
}

@mixin Button-AA---12---Small---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 12px;
  font-weight: 900;
  line-height: 1.33;
  letter-spacing: 0.5px;
  color: $basic-70;
}

@mixin C2-Caption---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $success-50;
}

@mixin C2-Caption---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $basic-70;
}

@mixin C2-Caption---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $error-60;
}

@mixin C2-Caption---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin C2-Caption---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $basic-90;
}

@mixin C2-Caption---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $basic-10;
}

@mixin C2-Caption---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: right;
  color: $primary-60;
}

@mixin C2-Caption---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $success-50;
}

@mixin C2-Caption---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $basic-70;
}

@mixin C2-Caption---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $transparent-basic-50;
}

@mixin C2-Caption---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $basic-90;
}

@mixin C2-Caption---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $basic-10;
}

@mixin C2-Caption---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $primary-60;
}

@mixin C2-Caption---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $success-50;
}

@mixin C2-Caption---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $basic-70;
}

@mixin C2-Caption---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $error-60;
}

@mixin C2-Caption---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin C2-Caption---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $basic-90;
}

@mixin C2-Caption---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $basic-10;
}

@mixin C2-Caption---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  text-align: center;
  color: $primary-60;
}

@mixin C2-Caption---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 11px;
  font-weight: 900;
  line-height: 1.45;
  color: $error-60;
}

@mixin C1-Caption---Right---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $success-50;
}

@mixin C1-Caption---Right---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $basic-70;
}

@mixin C1-Caption---Right---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $error-60;
}

@mixin C1-Caption---Right---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin C1-Caption---Right---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $basic-90;
}

@mixin C1-Caption---Right---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $basic-10;
}

@mixin C1-Caption---Right---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: right;
  color: $primary-60;
}

@mixin C1-Caption---Left---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $success-50;
}

@mixin C1-Caption---Left---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $basic-70;
}

@mixin C1-Caption---Left---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $error-60;
}

@mixin C1-Caption---Left---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $transparent-basic-50;
}

@mixin C1-Caption---Left---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $basic-90;
}

@mixin C1-Caption---Left---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $basic-10;
}

@mixin C1-Caption---Left---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  color: $primary-60;
}

@mixin C1-Caption---Center---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $success-50;
}

@mixin C1-Caption---Center---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $basic-70;
}

@mixin C1-Caption---Center---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $error-60;
}

@mixin C1-Caption---Center---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin C1-Caption---Center---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $basic-90;
}

@mixin C1-Caption---Center---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $basic-10;
}

@mixin C1-Caption---Center---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 11px;
  line-height: 1.45;
  text-align: center;
  color: $primary-60;
}

@mixin Button-Aa---10-Tiny---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $success-50;
}

@mixin Button-Aa---10-Tiny---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $basic-70;
}

@mixin Button-Aa---10-Tiny---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $error-60;
}

@mixin Button-Aa---10-Tiny---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-Aa---10-Tiny---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $basic-90;
}

@mixin Button-Aa---10-Tiny---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $basic-10;
}

@mixin Button-Aa---10-Tiny---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: right;
  color: $primary-60;
}

@mixin Button-Aa---10-Tiny---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $success-50;
}

@mixin Button-Aa---10-Tiny---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $basic-70;
}

@mixin Button-Aa---10-Tiny---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $error-60;
}

@mixin Button-Aa---10-Tiny---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $transparent-basic-50;
}

@mixin Button-Aa---10-Tiny---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $basic-90;
}

@mixin Button-Aa---10-Tiny---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $basic-10;
}

@mixin Button-Aa---10-Tiny---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  color: $primary-60;
}

@mixin Button-Aa---10-Tiny---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $success-50;
}

@mixin Button-Aa---10-Tiny---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $basic-70;
}

@mixin Button-Aa---10-Tiny---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $error-60;
}

@mixin Button-Aa---10-Tiny---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-Aa---10-Tiny---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $basic-90;
}

@mixin Button-Aa---10-Tiny---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $basic-10;
}

@mixin Button-Aa---10-Tiny---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  text-align: center;
  color: $primary-60;
}

@mixin Button-AA---10-Tiny---Right---Success-Support-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $success-50;
}

@mixin Button-AA---10-Tiny---Right---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $basic-70;
}

@mixin Button-AA---10-Tiny---Right---Error-Support-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $error-60;
}

@mixin Button-AA---10-Tiny---Right---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin Button-AA---10-Tiny---Right---Default-Text-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $basic-90;
}

@mixin Button-AA---10-Tiny---Right---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $basic-10;
}

@mixin Button-AA---10-Tiny---Right---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: right;
  color: $primary-60;
}

@mixin Button-AA---10-Tiny---Left---Success-Support-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $success-50;
}

@mixin Button-AA---10-Tiny---Left---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $basic-70;
}

@mixin Button-AA---10-Tiny---Left---Error-Support-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $error-60;
}

@mixin Button-AA---10-Tiny---Left---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $transparent-basic-50;
}

@mixin Button-AA---10-Tiny---Left---Default-Text-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $basic-90;
}

@mixin Button-AA---10-Tiny---Left---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $basic-10;
}

@mixin Button-AA---10-Tiny---Left---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  color: $primary-60;
}

@mixin Button-AA---10-Tiny---Center---Success-Support-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $success-50;
}

@mixin Button-AA---10-Tiny---Center---Hint-Text-02 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $basic-70;
}

@mixin Button-AA---10-Tiny---Center---Error-Support-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $error-60;
}

@mixin Button-AA---10-Tiny---Center---Disabled-Text-03 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin Button-AA---10-Tiny---Center---Default-Text-01 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $basic-90;
}

@mixin Button-AA---10-Tiny---Center---Alternative-Text-04 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $basic-10;
}

@mixin Button-AA---10-Tiny---Center---Accent-Text-05 {
  font-family: $pingfang;
  font-size: 10px;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.5px;
  text-align: center;
  color: $primary-60;
}

@mixin S1-Small---Right---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $success-50;
}

@mixin S1-Small---Right---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $basic-70;
}

@mixin S1-Small---Right---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $error-60;
}

@mixin S1-Small---Right---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $transparent-basic-50;
}

@mixin S1-Small---Right---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $basic-90;
}

@mixin S1-Small---Right---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $basic-10;
}

@mixin S1-Small---Right---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: right;
  color: $primary-60;
}

@mixin S1-Small---Left---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $success-50;
}

@mixin S1-Small---Left---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $basic-70;
}

@mixin S1-Small---Left---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $error-60;
}

@mixin S1-Small---Left---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $transparent-basic-50;
}

@mixin S1-Small---Left---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $basic-90;
}

@mixin S1-Small---Left---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $basic-10;
}

@mixin S1-Small---Left---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  color: $primary-60;
}

@mixin S1-Small---Center---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $success-50;
}

@mixin S1-Small---Center---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $basic-70;
}

@mixin S1-Small---Center---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $error-60;
}

@mixin S1-Small---Center---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin S1-Small---Center---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $basic-90;
}

@mixin S1-Small---Center---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $basic-10;
}

@mixin S1-Small---Center---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 10px;
  line-height: 1.6;
  text-align: center;
  color: $primary-60;
}

@mixin S2-Small---Right---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $success-50;
}

@mixin S2-Small---Right---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $basic-70;
}

@mixin S2-Small---Right---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $error-60;
}

@mixin S2-Small---Right---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $basic-90;
}

@mixin S2-Small---Right---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $basic-10;
}

@mixin S2-Small---Right---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $primary-60;
}

@mixin S2-Small---Left---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $success-50;
}

@mixin S2-Small---Left---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $basic-70;
}

@mixin S2-Small---Left---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $error-60;
}

@mixin S2-Small---Left---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $transparent-basic-50;
}

@mixin S2-Small---Left---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $basic-90;
}

@mixin S2-Small---Left---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $basic-10;
}

@mixin S2-Small---Left---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  color: $primary-60;
}

@mixin S2-Small---Center---Success-Support-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $success-50;
}

@mixin S2-Small---Center---Hint-Text-02 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $basic-70;
}

@mixin S2-Small---Center---Error-Support-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $error-60;
}

@mixin S2-Small---Center---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $transparent-basic-50;
}

@mixin S2-Small---Center---Default-Text-01 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $basic-90;
}

@mixin S2-Small---Center---Alternative-Text-04 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $basic-10;
}

@mixin S2-Small---Center---Accent-Text-05 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: center;
  color: $primary-60;
}

@mixin S2-Small---Right---Disabled-Text-03 {
  font-weight: normal;
  font-family: $pingfang;
  font-size: 9px;
  line-height: 1.78;
  text-align: right;
  color: $transparent-basic-50;
}
