@import '../../styles/colours.scss';
@import '../../styles/spacing.scss';
@import '../../styles/typography.scss';

.button {
  border: none;
  overflow: visible;
  color: inherit;
  font: inherit;
  background: transparent;
  line-height: normal;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-content: center;
  -webkit-align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  &--disabled {
    cursor: not-allowed;
  }
  &--ghost {
    background-color: transparent;
    border: 1px solid transparent;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:focus {
    outline: none !important;
  }
  &--outline {
    &--primary {
      background-color: transparent;
      border: 1px solid $basic-90;
      &--disabled {
        background-color: $basic-10;
        border: 1px solid $basic-90;
      }
    }
    &--secondary {
      background-color: $basic-20;
      border: 1px solid $basic-70;
      &--disabled {
        background-color: $transparent-basic-16;
        border: 1px solid $transparent-basic-16;
      }
    }
    &--success {
      background-color: $success-10;
      border: 1px solid $success-50;
      &--disabled {
        background-color: $transparent-basic-16;
        border: 1px solid $transparent-basic-16;
      }
    }
    &--danger {
      background-color: $error-10;
      border: 1px solid $error-60;
      &--disabled {
        background-color: $transparent-basic-16;
        border: 1px solid $transparent-basic-16;
      }
    }
  }
  &--fill {
    &--primary {
      background-color: $basic-90;
      border: 1px solid $basic-90;
      &:hover {
        background-color: $basic-80;
        border: 1px solid $basic-80;
      }
      &:active {
        background-color: $basic-100;
        border: 1px solid $basic-100;
      }
      &:focus {
        background-color: $basic-90;
        border: 1px solid $basic-90;
      }
      &--disabled {
        background-color: $transparent-basic-16 !important;
        border: 1px solid $transparent-basic-16 !important;
      }
    }
    &--secondary {
      background-color: $basic-30;
      border: 1px solid $basic-30;
      &:hover {
        background-color: $basic-20;
        border: 1px solid $basic-20;
      }
      &:active {
        background-color: $basic-40;
        border: 1px solid $basic-40;
      }
      &:focus {
        background-color: $basic-30;
        border: 1px solid $basic-30;
      }
      &--disabled {
        background-color: $transparent-basic-16 !important;
        border: 1px solid $transparent-basic-16 !important;
      }
    }
    &--success {
      background-color: $success-50;
      border: 1px solid $success-50;
      &:hover {
        background-color: $success-40;
        border: 1px solid $success-40;
      }
      &:active {
        background-color: $success-60;
        border: 1px solid $success-60;
      }
      &:focus {
        background-color: $success-50;
        border: 1px solid $success-50;
      }
      &--disabled {
        background-color: $transparent-basic-16 !important;
        border: 1px solid $transparent-basic-16 !important;
      }
    }
    &--danger {
      background-color: $error-60;
      border: 1px solid $error-60;
      &:hover {
        background-color: $error-50;
        border: 1px solid $error-50;
      }
      &:active {
        background-color: $error-70;
        border: 1px solid $error-70;
      }
      &:focus {
        background-color: $error-60;
        border: 1px solid $error-60;
      }
      &--disabled {
        background-color: $transparent-basic-16 !important;
        border: 1px solid $transparent-basic-16 !important;
      }
    }
    &--accent {
      background-color: $primary-50;
      border: 1px solid $primary-50;
      &:hover {
        background-color: $primary-40;
        border: 1px solid $primary-40;
      }
      &:active {
        background-color: $primary-60;
        border: 1px solid $primary-60;
      }
      &:focus {
        background-color: $primary-50;
        border: 1px solid $primary-50;
      }
      &--disabled {
        background-color: $transparent-basic-16 !important;
        border: 1px solid $transparent-basic-16 !important;
      }
    }
  }
  &--tiny {
    padding: $spacing-s;
    height: 24px;
    span:nth-child(2) {
      margin-left: $spacing-xs;
    }
  }
  &--small {
    padding: $spacing-s;
    height: 32px;
    span:nth-child(2) {
      margin-left: $spacing-s;
    }
  }
  &--medium {
    padding: $spacing-m;
    height: 40px;
    span:nth-child(2) {
      margin-left: $spacing-s;
    }
  }
  &--large {
    padding: $spacing-m;
    height: 48px;
    span:nth-child(2) {
      margin-left: $spacing-s;
    }
    &--sub-title {
      align-content: flex-start;
      -webkit-align-items: flex-start;
      padding: $spacing-xs $spacing-m 0px $spacing-m;
      span:nth-child(2) {
        margin-left: $spacing-xs;
      }
    }
  }
  &--giant {
    padding: $spacing-l;
    height: 56px;
    span:nth-child(2) {
      margin-left: $spacing-m;
    }
  }
  &__sub-title {
    display: block;
    position: absolute;
    @include Button-Aa---10-Tiny---Right---Hint-Text-02;
    bottom: 5px;
  }
  &__title {
    &--outline,
    &--ghost {
      &--tiny {
        &--primary {
          @include Button-Aa---10-Tiny---Left---Default-Text-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---10-Tiny---Left---Hint-Text-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---10-Tiny---Left---Success-Support-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---10-Tiny---Left---Error-Support-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---10-Tiny---Left---Accent-Text-05;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--small {
        &--primary {
          @include Button-Aa---12-Small---Left---Default-Text-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---12-Small---Left---Hint-Text-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---12-Small---Left---Success-Support-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---12-Small---Left---Error-Support-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---12-Small---Left---Accent-Text-05;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--medium {
        &--primary {
          @include Button-Aa---14-Medium---Left---Default-Text-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---14-Medium---Left---Hint-Text-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---14-Medium---Left---Success-Support-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---14-Medium---Left---Error-Support-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---14-Medium---Left---Accent-Text-05;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--large {
        &--primary {
          @include Button-Aa---16-Large---Left---Default-Text-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---16-Large---Left---Hint-Text-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---16-Large---Left---Success-Support-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---16-Large---Left---Error-Support-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---16-Large---Left---Accent-Text-05;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--giant {
        &--primary {
          @include Button-Aa---18-Giant---Left---Default-Text-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---18-Giant---Left---Hint-Text-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---18-Giant---Left---Success-Support-02;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---18-Giant---Left---Error-Support-01;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---18-Giant---Left---Accent-Text-05;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
    }
    &--fill {
      &--tiny {
        &--primary {
          @include Button-Aa---10-Tiny---Left---Default-Text-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---10-Tiny---Left---Hint-Text-02;
          color: $basic-90;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---10-Tiny---Left---Success-Support-02;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
          &--danger {
            @include Button-Aa---10-Tiny---Left---Error-Support-01;
            color: $basic-10;
            &--text-left {
              text-align: left;
              flex: 1;
            }
            &--disabled {
              color: $transparent-basic-50;
            }
          }
          &--accent {
            @include Button-Aa---10-Tiny---Left---Accent-Text-05;
            color: $basic-10;
            &--text-left {
              text-align: left;
              flex: 1;
            }
            &--disabled {
              color: $transparent-basic-50;
            }
          }
        }
      }
      &--small {
        &--primary {
          @include Button-Aa---12-Small---Left---Default-Text-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---12-Small---Left---Hint-Text-02;
          color: $basic-90;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---12-Small---Left---Success-Support-02;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---12-Small---Left---Error-Support-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---12-Small---Left---Accent-Text-05;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--medium {
        &--primary {
          @include Button-Aa---14-Medium---Left---Default-Text-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---14-Medium---Left---Hint-Text-02;
          color: $basic-90;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---14-Medium---Left---Success-Support-02;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---14-Medium---Left---Error-Support-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---14-Medium---Left---Accent-Text-05;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--large {
        &--primary {
          @include Button-Aa---16-Large---Left---Default-Text-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---16-Large---Left---Hint-Text-02;
          color: $basic-90;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---16-Large---Left---Success-Support-02;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---16-Large---Left---Error-Support-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---16-Large---Left---Accent-Text-05;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--giant {
        &--primary {
          @include Button-Aa---18-Giant---Left---Default-Text-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          @include Button-Aa---18-Giant---Left---Hint-Text-02;
          color: $basic-90;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          @include Button-Aa---18-Giant---Left---Success-Support-02;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          @include Button-Aa---18-Giant---Left---Error-Support-01;
          color: $basic-10;
          &--text-left {
            text-align: left;
            flex: 1;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          @include Button-Aa---18-Giant---Left---Accent-Text-05;
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
    }
  }
  &__icon {
    &--outline,
    &--ghost {
      &--tiny {
        font-size: 14px !important;
        margin-top: 2px;
        &--primary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-70;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $success-50;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $error-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $primary-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--small {
        font-size: 16px !important;
        margin-top: 1px;
        &--primary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-70;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $success-50;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $error-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $primary-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--medium {
        font-size: 20px !important;
        margin-top: -1px;
        &--primary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-70;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $success-50;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $error-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $primary-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--large {
        font-size: 24px !important;
        margin-top: 2px;
        &--primary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-70;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $success-50;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $error-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $primary-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--giant {
        font-size: 24px !important;
        margin-top: 1px;
        &--primary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-70;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $success-50;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $error-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $primary-60;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
    }
    &--fill {
      &--tiny {
        font-size: 14px !important;
        margin-top: 2px;
        &--primary {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--small {
        font-size: 16px !important;
        margin-top: 1px;
        &--primary {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--medium {
        font-size: 20px !important;
        margin-top: -1px;
        &--primary {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--large {
        font-size: 24px !important;
        margin-top: 2px;
        &--primary {
          color: $basic-10;
          &--sub-title {
            font-size: 21px !important;
            margin-top: 0;
          }
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
      &--giant {
        font-size: 24px !important;
        margin-top: 1px;
        &--primary {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--secondary {
          color: $basic-90;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--success {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--danger {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
        &--accent {
          color: $basic-10;
          &--disabled {
            color: $transparent-basic-50;
          }
        }
      }
    }
  }
}
