@import '../../styles/grid.scss';
@import '../../styles/typography.scss';
@import '../../styles/spacing.scss';

.landing {
  padding-bottom: $spacing-xxl-2;
  @include create-mq($grid__bp-md - 1, 'max') {
    padding-bottom: 0;
  }
  &__header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 998;
    margin-bottom: $spacing-xl;
    background-color: #fff;
    &__inner {
      padding: 20px 0;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      &__logo {
        flex: 1 1 0;
        &__image {
          display: block;
          @include create-mq($grid__bp-md - 1, 'max') {
            margin: 0 auto;
          }
        }
      }
    }
  }
  &__main {
    max-width: 660px;
    width: 100%;
    margin: 0 auto;
    &__message {
      margin-bottom: $spacing-xxl-2;
      &__title {
        @include H2-Headline---Left---Default-Text-01;
        margin-bottom: $spacing-xl;
        color: #000;
      }
      &__paragraph {
        @include B1-Body---Left---Default-Text-01;
        font-size: 16px;
        margin-bottom: $spacing-l;
        line-height: 22.4px;
        color: #000;
      }
    }
    &__button {
      margin-bottom: 56px;
      width: fit-content;
      text-decoration: none;
      background-color: #0f0f0f;
      @include create-mq($grid__bp-md - 1, 'max') {
        width: 100%;
      }
    }
    &__secured-banner {
      width: 100%;
      height: 276px;
      position: relative;
      background-image: url('./secured-2.jpg');
      background-size: cover;
      border-radius: $spacing-xs;
      @include create-mq($grid__bp-md - 1, 'max') {
        height: 335px;
        background-image: url('./secured-2-mobile.jpg');
        margin-left: -$spacing-xl;
        border-radius: 0;
        width: calc(100% + 2 *#{$spacing-xl});
      }
      &__content {
        background: rgba(255, 255, 255, 0.9);
        padding: $spacing-l;
        width: 373px;
        box-sizing: border-box;
        position: absolute;
        left: $spacing-xl;
        bottom: $spacing-xl;
        border-radius: $spacing-xs;
        @include create-mq($grid__bp-md - 1, 'max') {
          left: 0;
          top: inherit;
          bottom: 0;
          transform: inherit;
          width: 100%;
          border-radius: 0;
        }
        &__title {
          @include H1-Headline---Left---Default-Text-01;
          margin-bottom: $spacing-s;
          color: $basic-90;
        }
        &__text {
          @include B1-Body---Left---Default-Text-01;
          margin-bottom: $spacing-s;
          color: $basic-90;
        }
        &__link {
          &__link {
            @include L1-Link---Left---Default-Text-01;
            text-decoration: underline;
            color: $basic-90;
          }
        }
      }
    }
  }
}

.container {
  max-width: $grid__bp-xl * 1px;
  margin: 0 auto;

  padding-left: $spacing-xl;
  padding-right: $spacing-xl;
}
