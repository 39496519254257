$font-family: 'secured';
$font-path: './fonts';

$activity: '\e900';
$alert-octagon: '\e901';
$bell: '\e902';
$book: '\e903';
$calendar: '\e904';
$check-square: '\e905';
$check: '\e906';
$chevron-down: '\e907';
$chevron-left: '\e908';
$chevron-right: '\e909';
$chevron-up: '\e90a';
$chevrons-right: '\e90b';
$cross: '\e90c';
$discord: '\e90d';
$edit: '\e90e';
$external-link: '\e90f';
$eye-off: '\e910';
$eye: '\e911';
$home: '\e912';
$info: '\e913';
$loader: '\e914';
$log-out: '\e915';
$mail: '\e916';
$map: '\e917';
$menu: '\e918';
$newfile: '\e919';
$package: '\e91a';
$phone: '\e91b';
$play-circle: '\e91c';
$plus-circle: '\e91d';
$plus: '\e91e';
$refresh: '\e91f';
$search: '\e920';
$settings: '\e921';
$shopping-bag: '\e922';
$star: '\e923';
$trending-up: '\e924';
$upload: '\e925';
$user: '\e926';

@font-face {
  font-family: '#{$font-family}';
  src: url('#{$font-path}/#{$font-family}.eot?s7cvyp');
  src: url('#{$font-path}/#{$font-family}.eot?s7cvyp#iefix')
      format('embedded-opentype'),
    url('#{$font-path}/#{$font-family}.ttf?s7cvyp') format('truetype'),
    url('#{$font-path}/#{$font-family}.woff?s7cvyp') format('woff'),
    url('#{$font-path}/#{$font-family}.svg?s7cvyp##{$font-family}')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 16px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &__activity {
    &:before {
      content: $activity;
    }
  }
  &__alert-octagon {
    &:before {
      content: $alert-octagon;
    }
  }
  &__bell {
    &:before {
      content: $bell;
    }
  }
  &__book {
    &:before {
      content: $book;
    }
  }
  &__calendar {
    &:before {
      content: $calendar;
    }
  }
  &__check-square {
    &:before {
      content: $check-square;
    }
  }
  &__check {
    &:before {
      content: $check;
    }
  }
  &__chevron-down {
    &:before {
      content: $chevron-down;
    }
  }
  &__chevron-left {
    &:before {
      content: $chevron-left;
    }
  }
  &__chevron-right {
    &:before {
      content: $chevron-right;
    }
  }
  &__chevron-up {
    &:before {
      content: $chevron-up;
    }
  }
  &__chevrons-right {
    &:before {
      content: $chevrons-right;
    }
  }
  &__cross {
    &:before {
      content: $cross;
    }
  }
  &__discord {
    &:before {
      content: $discord;
    }
  }
  &__edit {
    &:before {
      content: $edit;
    }
  }
  &__external-link {
    &:before {
      content: $external-link;
    }
  }
  &__eye-off {
    &:before {
      content: $eye-off;
    }
  }
  &__eye {
    &:before {
      content: $eye;
    }
  }
  &__home {
    &:before {
      content: $home;
    }
  }
  &__info {
    &:before {
      content: $info;
    }
  }
  &__loader {
    &:before {
      content: $loader;
    }
  }
  &__log-out {
    &:before {
      content: $log-out;
    }
  }
  &__mail {
    &:before {
      content: $mail;
    }
  }
  &__map {
    &:before {
      content: $map;
    }
  }
  &__menu {
    &:before {
      content: $menu;
    }
  }
  &__newfile {
    &:before {
      content: $newfile;
    }
  }
  &__package {
    &:before {
      content: $package;
    }
  }
  &__phone {
    &:before {
      content: $phone;
    }
  }
  &__play-circle {
    &:before {
      content: $play-circle;
    }
  }
  &__plus-circle {
    &:before {
      content: $plus-circle;
    }
  }
  &__plus {
    &:before {
      content: $plus;
    }
  }
  &__refresh {
    &:before {
      content: $refresh;
    }
  }
  &__search {
    &:before {
      content: $search;
    }
  }
  &__settings {
    &:before {
      content: $settings;
    }
  }
  &__shopping-bag {
    &:before {
      content: $shopping-bag;
    }
  }
  &__star {
    &:before {
      content: $star;
    }
  }
  &__trending-up {
    &:before {
      content: $trending-up;
    }
  }
  &__upload {
    &:before {
      content: $upload;
    }
  }
  &__user {
    &:before {
      content: $user;
    }
  }
}
